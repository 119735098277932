<template>
  <div class="header-menu">
    <!-- Десктопное меню остаётся без изменений -->
    <ul class="desk-menu">
      <li>
        <router-link to="/profile/info" :class="{ active: isActive('/profile/info') }">About</router-link>
      </li>
      <li>
        <router-link to="/profile/history" :class="{ active: isActive('/profile/history') }">History</router-link>
      </li>
      <li>
        <router-link to="/profile/favorite" :class="{ active: isActive('/profile/favorite') }">Favorite</router-link>
      </li>
      <li>
        <router-link to="/profile/setting" :class="{ active: isActive('/profile/setting') }">Settings</router-link>
      </li>
      <li>
        <router-link to="/search" :class="{ active: isActive('/search') }">Search</router-link>
      </li>
      <li>
        <router-link to="/categories" :class="{ active: isActive('/categories') }">Services</router-link>
      </li>
      <li>
        <router-link to="#" :class="{ active: isActive('#') }">Careers</router-link>
      </li>
      <li>
        <router-link to="#" :class="{ active: isActive('#') }">Contacts</router-link>
      </li>
    </ul>

    <!-- Иконка бургер меню -->
    <button class="burger-btn" @click="toggleMenu">
      <v-icon>{{ isMenuOpen ? 'mdi-close' : 'mdi-menu' }}</v-icon>
    </button>

    <!-- Мобильное меню -->
    <div class="mob-menu custom-menu" :class="{ 'menu-open': isMenuOpen }">
      <ul>
        <li v-for="(item, index) in items" :key="index" :class="{ active: isActive(item.link) }">
          <router-link :to="item.link" v-if="!item.langList">{{ item.title }}</router-link>
          <!-- Пункт меню для выбора языков -->
          <div v-if="item.langList" @click="toggleLangMenu" class="lang-toggle">
            {{ item.title }}
            <v-icon>{{ langMenuOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </div>
          <!-- Подменю с языками -->
          <ul v-if="langMenuOpen" class="lang-item">
            <li v-for="lang in item.langList" :key="lang.value" @click="selectLanguage(lang)">
              {{ lang.label }}
              <!-- Отображение галочки для выбранного языка -->
              <v-icon v-if="selectedLang === lang.value" class="check-icon" height="100%">mdi-check</v-icon>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue';
import { useRoute } from 'vue-router';

const isMenuOpen = ref(false);
const langMenuOpen = ref(false);
const selectedLang = ref(null);
const route = useRoute();

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

const toggleLangMenu = () => {
  langMenuOpen.value = !langMenuOpen.value;
};

const selectLanguage = (lang) => {
  selectedLang.value = lang.value;
};

const isActive = (path) => {
  return route.path === path;
};

const items = reactive([
  { title: 'About', link: '/profile/info' },
  { title: 'History', link: '/profile/history' },
  { title: 'Favorite', link: '/profile/favorite' },
  { title: 'Setting', link: '/profile/setting' },
  { title: 'Search', link: '/search' },
  { title: 'Services', link: '/categories' },
  { title: 'Careers', link: '/' },
  { title: 'Contacts', link: '/' },
  { title: 'Lang', langList: [
      { label: 'Українська', value: 'ua' },
      { label: 'Русский', value: 'ru' },
      { label: 'English', value: 'en' },
    ]
  },
]);
</script>

<style lang="scss" scoped>
.mob-menu {
  display: none;
  @media(max-width: 767px) {
    display: block;
  }

  position: fixed;
  top: 114px;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #FCFCF7;
  color: #000;
  z-index: 9;
  transition: left 0.3s ease;
  overflow-y: auto;
  padding: 6px 0;
}

.mob-menu.menu-open {
  left: 0;
}

.v-list-item-title {
  color: rgb(128, 128, 128);
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
}

.mob-menu__wrap .v-list-item-title a {
  color: rgb(128, 128, 128);
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  text-decoration: unset;
}

.mob-menu ul {
  list-style: none;
  padding: 0;
}

.mob-menu li {
  padding: 0 17px;
  &.active {
    background: #E6D1F0;
  }
  a {
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-decoration: unset;
    width: 100%;
    padding: 12px 0;
    display: flex;
  }
}

.lang-item ul {
  list-style: none;
  padding: 0;
}

.lang-item li {
  padding-left: 10px;
  margin-bottom: 8px;
  cursor: pointer;
  color: rgb(0, 0, 0);
  font-size: 14px;
  font-weight: 600;
  line-height: 23px;
  text-decoration: unset;
  display: flex;
}

.burger-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #000;
  position: relative;
  z-index: 10;
}

.check-icon {
  margin-left: 8px;
  color: #AB6FD2;
}
</style>
