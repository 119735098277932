<template>
    <div class="footer__menu">
        <div class="footer__menu--title">{{menu.title}}</div>
        <div class="footer__menu--list">
            <div class="footer__menu--item" v-for="(item, index) in menu.list" :key="index">
                <router-link :to="item.link">{{item.title}}</router-link>
            </div>
        </div>
    </div>
</template>
<script setup>
import {defineProps, computed} from "vue";

const props = defineProps({
    menu: {
        type: Object,
        required: true
    }
});
const menu = computed(() => props.menu);
</script>

<style lang="scss" scoped>
</style>
