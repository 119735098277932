<template>
  <div class="footer__subscribe">
    <div class="footer__subscribe--title">Stay in the loop</div>
    <form @submit.prevent="handleSubmit" class="footer__subscribe--form">
      <input
          type="email"
          v-model="email"
          :class="{ 'is-invalid': emailError }"
          placeholder="Enter your email"
          required
      >
      <button type="submit">Submit</button>
      <div v-if="emailError" class="error-message">{{ emailError }}</div>
    </form>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const email = ref('');
const emailError = ref('');

const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
};

const handleSubmit = () => {
    if (validateEmail(email.value)) {
        // Здесь можно отправить данные на сервер
        console.log('Email отправлен:', email.value);
        emailError.value = '';
    } else {
        emailError.value = 'Пожалуйста, введите действительный email';
    }
};
</script>

<style lang="scss" scoped>
</style>
