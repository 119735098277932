<template>
    <router-view></router-view>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App',
})
</script>

<style>
/* Ваши стили здесь */
</style>
