<template>
    <header class="container">
        <div class="header-container">
            <router-link to="/" class="logo">
                <img :src="logo" alt="">
            </router-link>
            <HeaderMenu/>
            <HeaderLang/>
            <HeaderLogin/>
        </div>
    </header>
</template>

<script setup>
import HeaderMenu from "@/components/Header/HeaderMenu";
import HeaderLang from "@/components/Header/HeaderLang";
import HeaderLogin from "@/components/Header/HeaderLogin";
import {reactive} from "vue";

const logo = reactive(new URL('@/assets/img/logo.png', import.meta.url))
</script>

<style lang="scss">
</style>
