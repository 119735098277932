<template>
  <v-menu class="footer-menu" transition="slide-y-transition">
    <template v-slot:activator="{ props }">
      <v-btn variant="plain" :ripple="false" v-bind="props" class="lang-btn">
        <span class="lang-cur">{{ selected.value }}</span>
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M5.00003 7.85018C5.17925 7.85018 5.35845 7.78175 5.49508 7.64518L9.79486 3.34536C10.0684 3.07184 10.0684 2.62837 9.79486 2.35496C9.52145 2.08155 9.07807 2.08155 8.80452 2.35496L5.00003 6.15968L1.19551 2.35509C0.921988 2.08168 0.478652 2.08168 0.205263 2.35509C-0.0683908 2.6285 -0.0683908 3.07197 0.205263 3.34549L4.50497 7.64531C4.64168 7.78191 4.82087 7.85018 5.00003 7.85018Z"
              fill="black"/>
        </svg>
      </v-btn>
    </template>
    <v-list class="lang-menu">
      <v-list-item
          v-for="(item, i) in langLiset"
          :key="i"
          @click="selectLanguage(item)"
          :ripple="false"
      >
        <v-list-item-title>{{ item.label }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup>
import {reactive, ref} from 'vue';

const selected = ref({label: 'Select Language', value: 'eng'});
const langLiset = reactive([
  {label: 'Українська', value: 'ukr'},
  {label: 'Русский', value: 'rus'},
  {label: 'English', value: 'eng'},
  {label: 'Українська', value: 'ukr'},
  {label: 'Русский', value: 'rus'},
  {label: 'English', value: 'eng'},
  {label: 'Українська', value: 'ukr'},
  {label: 'Русский', value: 'rus'},
  {label: 'English', value: 'eng'},
  {label: 'Українська', value: 'ukr'},
  {label: 'Русский', value: 'rus'},
  {label: 'English', value: 'eng'},
  {label: 'Українська', value: 'ukr'},
  {label: 'Русский', value: 'rus'},
  {label: 'English', value: 'eng'},
]);

const selectLanguage = (item) => {
  selected.value = item;
};
</script>
<style lang="scss">

</style>